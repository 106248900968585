import { FooterProps } from '@/components/Footer'
import atImage from '@/images/footer/at.webp'
import facebookImage from '@/images/footer/facebook.webp'
import instagramImage from '@/images/footer/instagram.webp'
import linkedinImage from '@/images/footer/linkedin.webp'
import twitterImage from '@/images/footer/twitter.webp'
import wifiImage from '@/images/footer/wifi.webp'
import youtubeImage from '@/images/footer/youtube.webp'

export const socialMediaList = [
  {
    image: youtubeImage,
    link: 'https://www.youtube.com/@DeHaat/featured',
    alt: 'youtube',
  },
  {
    image: linkedinImage,
    link: 'https://www.linkedin.com/company/dehaat/',
    alt: 'linkedin',
  },
  {
    image: twitterImage,
    link: 'https://twitter.com/DeHaatTM?s=09',
    alt: 'twitter',
  },
  {
    image: facebookImage,
    link: 'https://www.facebook.com/DeHaatTM/',
    alt: 'facebook',
  },
  {
    image: instagramImage,
    link: 'https://www.instagram.com/dehaat/?hl=en',
    alt: 'instagram',
  },
  {
    image: atImage,
    link: '',
    alt: 'at',
  },
  {
    image: wifiImage,
    link: '',
    alt: 'wifi',
  },
]

export const footerLinks: FooterProps[] = [
  {
    heading: 'Quick Links',
    content: [
      {
        label: 'Crop Guide',
        link: 'https://dehaat.in/en/crop?redirect_uri=/crop-guide',
      },
      {
        label: 'Crop Doctor',
        link: 'https://dehaat.in/en/ask-question',
      },
      {
        label: 'Animal Doctor',
        link: 'https://bot.agrevolution.in/cattle-video-call-mweb',
      },
      {
        label: 'Farm Talk',
        link: 'https://dehaat.in/en?tab=1',
      },
      {
        label: 'Farm Monitoring',
        link: 'https://dehaat.in/en/prakshep',
      },
    ],
  },
  // {
  //   heading: 'More Links',
  //   content: [
  //     {
  //       label: 'Know your soil',
  //       link: 'https://agrevolution.in/know-your-soil',
  //     },
  //     {
  //       label: 'Agri input',
  //       link: 'https://agrevolution.in/agri-input',
  //     },
  //     {
  //       label: 'Agri output',
  //       link: 'https://agrevolution.in/agri-output-Harvest-and-Market-Access/',
  //     },
  //     {
  //       label: 'Growth and Health',
  //       link: 'https://agrevolution.in/health-growth-yield-prediction',
  //     },
  //     {
  //       label: 'Advisory',
  //       link: 'https://agrevolution.in/advisory-farmers-helpline-and-support',
  //     },
  //     {
  //       label: 'Agri Financing',
  //       link: 'https://agrevolution.in/Agricultural-Finance-Credit-Insurance',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Dehaat Services',
  //   content: [
  //     {
  //       label: 'Online shop',
  //       link: `?tab=2`,
  //       isInternal: true,
  //     },
  //     {
  //       label: 'Soil test',
  //       link: 'https://doxy.me/manishyadav',
  //     },
  //     {
  //       label: 'Crop doctor',
  //       link: '',
  //     },
  //     {
  //       label: 'Pashu Doctor',
  //       link: '',
  //     },
  //     {
  //       label: 'Farm Monitoring',
  //       link: '/prakshep',
  //       isInternal: true,
  //     },
  //     {
  //       label: 'Weather',
  //       link: '',
  //     },
  //     {
  //       label: 'Insurance',
  //       link: '/insurance',
  //       isInternal: true,
  //     },
  //     {
  //       label: 'Farmer Credit',
  //       link: '',
  //     },
  //     {
  //       label: 'Agri Videos',
  //       link: '',
  //     },
  //   ],
  // },
  {
    heading: 'Organisation',
    content: [
      {
        label: 'Corporate Website',
        link: 'https://agrevolution.in/',
      },
      {
        label: 'About Dehaat',
        link: 'https://agrevolution.in/company',
      },
      {
        label: 'Careers at Dehaat',
        link: 'https://agrevolution.in/careers',
      },
      {
        label: 'Authors And Experts',
        link: '',
      },
      {
        label: 'Contact Us',
        link: 'https://agrevolution.in/contact-us',
      },
    ],
  },
  {
    heading: 'Legals',
    content: [
      {
        label: 'Privacy Policy',
        link: 'https://policies.agrevolution.in/privacy-policy/',
      },
      {
        label: 'Return Policy',
        link: '',
      },
      {
        label: 'Terms and Conditions',
        link: 'https://policies.agrevolution.in/terms-and-conditions/',
      },
      {
        label: 'Responsible Disclosure',
        link: 'https://agrevolution.in/responsible-disclosure/',
      },
    ],
  },
]

export const productLinks = [
  {
    heading: 'Purchase according to category',
    content: [
      {
        title: 'Fungicide',
        description:
          'Antracol is a high-quality fungicide that protects crops from diseases like blight, grey leaf spots, etc. It also increases the greening effect of crops by solving the problem of zinc deficiency in crops. The effect of this fungicide lasts on the crops for 6-8 days.',
        link: 'https://agrevolution.in/Agricultural-Finance-Credit-Insurance',
      },
      {
        title: 'Fungicide',
        description:
          'Antracol is a high-quality fungicide that protects crops from diseases like blight, grey leaf spots, etc. It also increases the greening effect of crops by solving the problem of zinc deficiency in crops. The effect of this fungicide lasts on the crops for 6-8 days.',
        link: 'https://agrevolution.in/Agricultural-Finance-Credit-Insurance',
      },
      {
        title: 'Fungicide',
        description:
          'Antracol is a high-quality fungicide that protects crops from diseases like blight, grey leaf spots, etc. It also increases the greening effect of crops by solving the problem of zinc deficiency in crops. The effect of this fungicide lasts on the crops for 6-8 days.',
        link: 'https://agrevolution.in/Agricultural-Finance-Credit-Insurance',
      },
      {
        title: 'Fungicide',
        description:
          'Antracol is a high-quality fungicide that protects crops from diseases like blight, grey leaf spots, etc. It also increases the greening effect of crops by solving the problem of zinc deficiency in crops. The effect of this fungicide lasts on the crops for 6-8 days.',
        link: 'https://agrevolution.in/Agricultural-Finance-Credit-Insurance',
      },
      {
        title: 'Fungicide',
        description:
          'Antracol is a high-quality fungicide that protects crops from diseases like blight, grey leaf spots, etc. It also increases the greening effect of crops by solving the problem of zinc deficiency in crops. The effect of this fungicide lasts on the crops for 6-8 days.',
        link: 'https://agrevolution.in/Agricultural-Finance-Credit-Insurance',
      },
    ],
  },
  {
    heading: 'Purchase according to Crop',
    content: [
      {
        title: 'Green chilli',
        description:
          'rdh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan,',
        link: 'https://agrevolution.in/advisory-farmers-helpline-and-support',
      },
      {
        title: 'Green chilli',
        description:
          'rdh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan,',
        link: 'https://agrevolution.in/advisory-farmers-helpline-and-support',
      },
      {
        title: 'Green chilli',
        description:
          'rdh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan,',
        link: 'https://agrevolution.in/advisory-farmers-helpline-and-support',
      },
      {
        title: 'Green chilli',
        description:
          'rdh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan,',
        link: 'https://agrevolution.in/advisory-farmers-helpline-and-support',
      },
      {
        title: 'Green chilli',
        description:
          'rdh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan, Ardh galan,',
        link: 'https://agrevolution.in/advisory-farmers-helpline-and-support',
      },
    ],
  },
]
