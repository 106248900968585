import Image from 'next/image';
import Link from 'next/link';
import SubHeading from '@/components/SubHeading';
import { footerLinks, socialMediaList } from '@/constants/footer';
export interface FooterProps {
  heading: string;
  content: Content[];
}
interface Content {
  label: string;
  link: string;
  isInternal?: boolean;
}
interface StatsDataItem {
  id: number;
  value: string;
  name: string;
}
interface StatsData {
  image_url: string;
  data: StatsDataItem[];
}
export interface FooterData {
  static_strip: StatsData;
}
interface Props {
  containerClassname?: string;
  footerData?: FooterData | null;
}
const FooterList = ({
  heading,
  content
}: FooterProps) => {
  return <div className="px-4 py-5" data-sentry-component="FooterList" data-sentry-source-file="Footer.tsx">
      <div className="flex mb-5">
        <div className="bg-primary-100 rounded w-1 mr-4" />
        <SubHeading data-sentry-element="SubHeading" data-sentry-source-file="Footer.tsx">{heading}</SubHeading>
      </div>
      <div className="flex flex-col gap-4 px-5">
        {content.map((item, index) => {
        return item.link !== '' ? <Link href={item.link} key={index} className="text-white text-sm" target={`${!item.isInternal ? '_blank' : ''}`}>
              {item.label}
            </Link> : null;
      })}
      </div>
    </div>;
};
const Footer = ({
  containerClassname = '',
  footerData
}: Props) => {
  const statsData: StatsData | null = footerData ? footerData.static_strip : null;
  return <footer data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      {statsData?.data && statsData.data.length > 0 && <div className="bg-primary-20 flex justify-center px-2 md:px-12">
          {statsData?.image_url && <div className="mr-4 md:mr-8">
              <Image src={statsData?.image_url} alt="Promo logo" width={300} height={500} className="object-contain w-40 h-40 md:h-20 md:w-20" />
            </div>}
          <div className="grid grid-cols-2 gap-3 md:flex md:justify-between md:items-center w-full py-4 max-w-6xl">
            {statsData?.data.map((item, index) => <div key={index}>
                <p className="text-primary-100 font-nato-semibold text-lg">
                  {item.value}
                </p>
                <p className="text-neutral-70 text-sm">{item.name}</p>
              </div>)}
          </div>
        </div>}
      <div className={`bg-primary-100 text-white pb-20 m-auto w-full md:flex md:justify-between md:flex-wrap ${containerClassname}`}>
        {footerLinks.map((items, index) => <FooterList key={index} {...items} />)}
        <div className="px-4 py-5">
          <SubHeading className="mb-5 px-5" data-sentry-element="SubHeading" data-sentry-source-file="Footer.tsx">Contact Us</SubHeading>
          <div className="px-5">
            <div>
              <p className="text-sm">Missed Call to connect:</p>
              <a href={`tel:18001036110`} className="inline-block bg-white text-primary-100 rounded-lg px-8 py-2 font-nato-semibold mt-2">
                1800 1036 110
              </a>
            </div>
            <div className="mt-4">
              <p className="text-sm">Whatsapp:</p>{' '}
              <a href={`https://wa.me/8506909095`} className="inline-block bg-white text-primary-100 rounded-lg px-8 py-2 font-nato-semibold mt-2" target="_blank" rel="noopener noreferrer">
                +91 8506909095
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col px-4 py-5 gap-5">
          <div className="flex">
            <div className="bg-primary-100 rounded w-1 mr-4" />
            <SubHeading data-sentry-element="SubHeading" data-sentry-source-file="Footer.tsx">Follow us on</SubHeading>
          </div>
          <div className="px-5">
            <div className="flex gap-4 items-center">
              {socialMediaList.map((item, index) => {
              return item.link !== '' ? <Link href={item.link} key={index} className="text-white text-sm cursor-pointer" target="_blank">
                    <Image src={item.image} alt={item.alt} width={32} height={32} />
                  </Link> : null;
            })}
            </div>
            <SubHeading className="mt-4" data-sentry-element="SubHeading" data-sentry-source-file="Footer.tsx">
              <Link href="mailto:contact@agrevolution.in" className="hover:underline" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
                Contact@agrevolution.in
              </Link>
            </SubHeading>
          </div>
          <div className="text-sm text-white border-t-2 border-solid border-gray-30 pt-5 px-5">
            Copyright @DeHaat 2024
          </div>
          <div className="text-sm text-white px-5">All rights reserved</div>
        </div>
      </div>
    </footer>;
};
export default Footer;